/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { defineNuxtRouteMiddleware, navigateTo, useNuxtApp } from '#app'
import { DataRequestStatus } from '~/src/Domain/DataRequest/DataRequestStatus'
import useService from '~/src/UserInterface/App/composables/Container/useService'
import useDataRequestorState from '~/src/UserInterface/DataRequest/composables/useDataRequestorState'

/**
 * This middleware must be called on data requestor pages
 *
 * Prerequisites for this middleware:
 * - The user must be authenticated as a data request recipient
 *
 * This middleware checks if there is a 'data_request' cookie value. If available it fetches
 * the data request details and populates state.
 */
export default defineNuxtRouteMiddleware(async (to) => {
  const logger = useService('logger')
  logger.info('[DATA_REQUEST_MUST_NOT_BE_FINISHED] Middleware fired')

  const { getDataRequest } = useDataRequestorState()
  if (![DataRequestStatus.ARCHIVED].includes(getDataRequest().value.status)) {
    return
  }

  if (
    typeof to.params.dataRequestRecipientId !== 'string'
    || typeof to.params.dataRequestSlug !== 'string'
    || typeof to.params.dataRequestId !== 'string'
  ) {
    logger.error(
      '[DATA_REQUEST_MUST_NOT_BE_FINISHED] Route does not have the correct url parameters. Redirecting to error page.',
    )

    // eslint-disable-next-line ts/no-unsafe-argument
    return navigateTo(useNuxtApp().$localePath('dataRequest-Error'))
  }

  logger.info(
    '[DATA_REQUEST_MUST_NOT_BE_FINISHED] DataRequest is fulfilled. Redirecting to success page.',
  )

  return navigateTo(
    // eslint-disable-next-line ts/no-unsafe-argument
    useNuxtApp().$localePath({
      name: 'dataRequest-SuccessStep',
      params: {
        dataRequestId: to.params.dataRequestId,
        dataRequestSlug: to.params.dataRequestSlug,
        dataRequestRecipientId: to.params.dataRequestRecipientId,
      },
    }),
  )
})
